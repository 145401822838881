<template>
    <div class="ol-dashboard__account">
        <LoadingImg v-if="loading" />  
        <LoadingImg v-if="loadingTrack" />    

        <div class="ol-dashboard__account-item">

            <h4 class="ol-dashboard__container-title">Claim Event</h4>
            <Alert v-if="alertMessage !=null " :type="alertType" :collection="alertMessage"/>

            <div class="add-fix">
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Event*</label>
                    <select class="ol-input ol-input-select" v-model="form.more_events_id">
                        <option v-for="event in events" :key="event.id+'-event-option'" :value="event.id">{{event.title}}</option>
                    </select>
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Nama Lengkap*</label>
                    <input type="text" v-model="form.name" placeholder="" class="ol-input">
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Email*</label>
                    <input type="text" v-model="form.email" placeholder="" class="ol-input">
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Alamat Lengkap*</label>
                    <input type="text" v-model="form.address"  class="ol-input">
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Nama Instagram*</label>
                    <input type="text" v-model="form.account_ig" placeholder="" class="ol-input">
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">No HP*</label>
                    <input type="text" v-model="form.phone" placeholder="0812xxxxxxxx" class="ol-input">
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Screenshot DM*</label>
                    <input type="file" @change="attachFileUpload('file_evidence',$event)" id="file_evidence"  class="ol-input">
                    <span>Format File : jpg, jpeg, png</span>
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Foto KTP*</label>
                    <input type="file" @change="attachFileUpload('file_ktp',$event)" id="file_ktp" class="ol-input">
                    <span>Format File : jpg, jpeg, png</span>
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Foto NPWP</label>
                    <input type="file" @change="attachFileUpload('file_npwp',$event)" id="file_npwp" class="ol-input">
                    <span>Format File : jpg, jpeg, png</span>
                </div>
                <div class="ol-input-box ol-input-box--full">
                    <button type="button" class="ol-btn" @click="onSave()">Simpan</button>
                </div>                  
            </div>
            
            <div class="ol-checkout-content__shipping-list">
                <div class="ol-checkout-content__shipping-list-head">
                    <div class="ol-checkout-content__shipping-list-head-item" style="text-align:center;width:25%">
                        Event
                    </div>
                    <div class="ol-checkout-content__shipping-list-head-item" style="text-align:center;width:35%">
                        Informasi
                    </div>
                    <div class="ol-checkout-content__shipping-list-head-item" style="text-align:center;width:35%">
                        Status
                    </div>
                    <div class="ol-checkout-content__shipping-list-head-item" style="text-align:center;width:5%">
                        Aksi
                    </div>
                </div>
                <div v-if="loadingList" class="ol-dashboard__order-loop">
                    <div class="ol-dashboard__order-list">
                        <div class="ol-dashboard__order-list-item ol-dashboard__order-list-item--head">
                            <SkeletonLoader width="100%" height="100px" />
                        </div>
                        <div class="ol-dashboard__order-list-item ol-dashboard__order-list-item--head">
                            <SkeletonLoader width="100%" height="100px" />
                        </div>
                        <div class="ol-dashboard__order-list-item ol-dashboard__order-list-item--head">
                            <SkeletonLoader width="100%" height="100px" />
                        </div>
                        
                    </div>
                </div>
                <div v-else v-for="claim in myClaims" :key="claim.id+'-claim'" class="ol-checkout-content__shipping-list-body list-event" style="padding-top:10px">
                    <div class="ol-checkout-content__shipping-list-body-item" style="padding-left:0px;width:25%">
                        <span><b> {{ claim.r_more_events.title }}</b> <br> {{ claim.created_date_indonesia }}</span>
                    </div>
                    <div class="ol-checkout-content__shipping-list-body-item" style="padding-left:60px;width:35%">
                        <span><b>{{ claim.name }}</b></span>
                        <p>{{ claim.email }} <br>{{ claim.phone }}<br>{{ claim.address }}<br>Instagram  : {{ claim.account_ig }}<br></p>
                    
                    </div>
                    <div class="ol-checkout-content__shipping-list-body-item" style="padding-left:60px;width:35%">
                        <h4 class="ol-dashboard__order-list-item-id">{{ claim.status_description }}</h4>
                        <br>
                        <div class="add-fix"></div>
                        <small v-if="claim.status == 91">Ditolak karena : {{claim.description}}</small>
                    </div>
                    <div  class="ol-checkout-content__shipping-list-body-item" style="padding-left:0px;width:5%">
                        <a v-if="claim.status == 1" title="Hapus" v-on:click="onDelete(claim.id)"><i class="fas fa-trash"></i></a>
                        <a v-if="claim.status == 3" title="Lacak Pengiriman" v-on:click="onTracking(claim)"><i class="fas fa-truck"></i></a>
                    </div>
                </div>
                <div v-if="myClaims.length > 0" class="ol-paging">
                    <nav>
                        <ul class="pagination">
                            <li class="page-item disabled" aria-disabled="true" aria-label="« Previous">
                                <a @click="toPage(myClaimsInfo.page_active == 1 ? 1 : myClaimsInfo.page_active - 1)" class="page-link" aria-hidden="true">‹</a>
                            </li>
                            <li  v-for="i in myClaimsInfo.total_pages" :key="'page-'+i" :class="'page-item page-item-'+i" :data-page="i" aria-current="page">
                                <a @click="toPage(i,filter)" class="page-link">{{i}}</a>
                            </li>
                            <li class="page-item">
                                <a @click="toPage(myClaimsInfo.page_active == myClaimsInfo.total_pages ? myClaimsInfo.page_active : myClaimsInfo.page_active+1)" class="page-link" rel="next" aria-label="Next »">›</a>
                            </li>
                        </ul>
                    </nav>

                </div>
            </div>
        </div>
                    
        <Popup title="Lacak Pengiriman" id="popup-tracking">
            <div style="width:100%!important">
                <div v-if="tracking.jsonResi && tracking.jsonResi.length > 0" class="StepProgress" style="margin:10px;">
                    <div class="StepProgress-item" v-for="(item,i) in tracking.jsonResi" :key="i-'-resi'"><strong>{{item.datetime}}</strong> {{item.description}}</div>
                </div>
            </div>
        </Popup> 
    </div>
</template>

<script>

import { mapGetters,mapActions } from 'vuex'
import SkeletonLoader from '@/components/loading/skeletonLoader'
import Popup from '@/components/popup/Popup.vue'
export default {
    name:"ProfileEvent",
    components:{
        SkeletonLoader
    },
    data(){
        return {
            alertType:"info",
            alertMessage:"",
            loading:false,
            loadingList:false,
            loadingTrack:false,
            form:{
                more_events_id:null,
                email: null,
                name: null,
                account_ig: null,
                phone: null,
                address: null,
                file_ktp: null,
                file_npwp: null,
                file_evidence: null,
            }
        }
    },
    computed:{
        ...mapGetters('profile/event', ['myClaims','myClaimsInfo','events','tracking']),
    },
    methods:{
        ...mapActions('profile/event', ['fetchMyClaims','fetchEvents','fetchTracking','saveClaim','deleteClaim']),

        attachFileUpload(key,event){
            this.form[key] = event.target.files[0];

            //5MB
            if(event.target.files[0].size > 5000000){
                $("input:file#"+key).val("");
                this.alertMessage = { claim : ["File maksimal 5MB"]};
                this.alertType = "danger";
                this.jqScrolling2top();
            }
        },
        resetForm(){
            for ( let key in this.form ) {
                this.form[key] = null;
            };
            $("input:file").val("");
        },
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        async toPage(page, payload = {}){
            payload.page = page;
            this.loadingList = true;
            let result = await this.fetchMyClaims(payload);
            this.loadingList = false;
            if(!result.success) this.$toast.open({ message: "Gagal mendapatkan data klaim", type: "error", duration: 5000, });

            $(".page-item").removeClass("active");
            $(".page-item-"+page).addClass("active");
        },
        async onSave(){
            this.loading = true;
            let formData = new FormData();

            for ( let key in this.form ) {
                if(this.form[key]) formData.append(key, this.form[key])
            };
            
            let result = await this.saveClaim(formData);
            if(!result.success){
                this.alertMessage =_.isObject(result.data) ? result.data: {claim: ["Gagal menyimpan klaim"]};
                this.alertType = "danger";
            }else{
                this.alertMessage = { claim : ["Berhasil menyimpan klaim"]};
                this.alertType = "success";
                this.resetForm();
                await this.toPage(1);
            }
            this.jqScrolling2top();
            this.loading = false;
        },
        async onDelete(id){
            this.alertMessage=null;
            Swal.fire({
                text: "Yakin ingin dihapus?",
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#364b7b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loadingList = true;
                    const isSuccess = await this.deleteClaim(id);
                    if(isSuccess){
                        this.alertMessage = { claim : ["Berhasil menghapus klaim"]};
                        this.alertType = "success";
                        await this.toPage(1);
                    }else{
                        this.alertMessage =_.isObject(result.data) ? result.data: {claim: ["Gagal menyimpan klaim"]};
                        this.alertType="danger"
                    }
                    this.jqScrolling2top();
                    this.loadingList = false;  

                }
            })
        },

        async onTracking(data){
            this.loadingTrack = true;
            await this.fetchTracking(data);
            this.showPopup("popup-tracking");
            this.loadingTrack = false;
        },

        showPopup(id = null, show = true) {
            show ? Popup.methods.show(id) : Popup.methods.hide(id);
        },

    },
    created(){
        this.fetchEvents();
    },
    async mounted(){
        await this.toPage(1);
    }
}
</script>
<style scoped>
    .list-event{
        height:150px;
    }
    @media screen and (max-device-width: 640px) {
        .list-event{
            height:250px;
        }
    }
    .StepProgress {
        position: relative;
        padding-left: 45px;
        list-style: none;
        overflow-y: auto; height:300px;
    }
    .StepProgress::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        width: 10px;
        height: 100%;
        border-left: 2px solid #CCC;
    }
    .StepProgress-item {
        position: relative;
        counter-increment: list;
    }
    .StepProgress-item:not(:last-child) {
        padding-bottom: 20px;
    }
    .StepProgress-item::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -30px;
        height: 100%;
        width: 10px;
    }
    .StepProgress-item::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -37px;
        width: 12px;
        height: 12px;
        border: 2px solid #CCC;
        border-radius: 50%;
        background-color: #FFF;
    }
    .StepProgress-item.is-done::before {
        border-left: 2px solid green;
    }
    .StepProgress-item.is-done::after {
        content: "✔";
        font-size: 10px;
        color: #FFF;
        text-align: center;
        border: 2px solid green;
        background-color: green;
    }
    .StepProgress-item.current::before {
        border-left: 2px solid green;
    }
    .StepProgress-item.current::after {
        content: counter(list);
        padding-top: 1px;
        width: 19px;
        height: 18px;
        top: -4px;
        left: -40px;
        font-size: 14px;
        text-align: center;
        color: green;
        border: 2px solid green;
        background-color: white;
    }
    .StepProgress strong {
        display: block;
    }

</style>